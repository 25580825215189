import { useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEventsContext } from "../../contexts/EventsContext";
import strings from "../../i18n/i18nStrings";
import styles from "./EventDisplayList.module.scss";
import FiltersHeader from "../filters/filtersHeader/FiltersHeader";
import Button, { BUTTON_SIZE, BUTTON_VARIANTS } from "../../ui/button/Button";
import EmptyDisplayList from "./emptyDisplayList/EmptyDisplayList";
import EventDisplayListItem from "./eventDisplayListItem/EventDisplayListItem";
import { useFilterContext } from "../../contexts/FilterContext";
import FiltersInputs from "../filters/filtersInputs/FiltersInputs";
import { useAppContext } from "../../contexts/AppContext";
import ProfileInfoModule from "../profileInfoModule/ProfileInfoModule";
import CreateOrEditEvent from "../createOrEditEvent/CreateOrEditEvent";
import Loader from "../loader/Loader";

const EventDisplayList = () => {
    const { showCompleteProfileModule } = useAppContext();
    const { showFilters } = useFilterContext();
    const {
        events,
        eventsLoading,
        pageNum,
        onPageChange,
        hasMoreEvents,
    } = useEventsContext();

    const [showCreateEvent, setShowCreateEvent] = useState(false);
    const onEventCreation = () => setShowCreateEvent(true);
    const onCancelEventCreation = () => setShowCreateEvent(false);

    return (
        <>
            <div className={styles.sidebarContainer}>
                {showFilters && (
                    <div className={styles.filtersContainer}>
                        <FiltersInputs />
                    </div>
                )}
                <ProfileInfoModule />
                <div
                    className={`
                        ${styles.eventsListContainer}
                        ${(showCreateEvent || showFilters || showCompleteProfileModule) ? styles.hiddenMobile : ''}
                    `}
                >
                    <FiltersHeader />
                    <div
                        className={styles.eventsContainer}
                        id="scrollableDiv"
                    >
                        {
                            eventsLoading && !events.length ? (
                                <Loader />
                            ) : events.length ? (
                                <InfiniteScroll
                                    dataLength={events.length} //This is important field to render the next data
                                    next={() => onPageChange(pageNum + 1)}
                                    hasMore={hasMoreEvents}
                                    loader={<h4>{strings.common.loading}</h4>}
                                    endMessage={
                                        <p className={styles.infiniteTextContainer}>
                                            <b>
                                                {strings.createOrEditEventScreen.endOfList}
                                            </b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    className={styles.eventsContainerDisplayItems}
                                    // below props only if you need pull down functionality
                                    refreshFunction={() => onPageChange(1)}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <h3 className={styles.infiniteTextContainer}>
                                            &#8595; {strings.createOrEditEventScreen.pullToRefresh}
                                        </h3>
                                    }
                                    releaseToRefreshContent={
                                        <h3 className={styles.infiniteTextContainer}>
                                            &#8593; {strings.createOrEditEventScreen.releaseToRefresh}
                                        </h3>
                                    }
                                >
                                    {
                                        events.map((event, idx) => (
                                            <EventDisplayListItem
                                                key={idx}
                                                event={event}
                                            />
                                        ))
                                    }
                                </InfiniteScroll>
                            ) : (
                                <EmptyDisplayList
                                    onCtaClick={onEventCreation}
                                />
                            )
                        }
                    </div>
                    <div className={styles.createButtonContainer}>
                        <Button
                            onPress={onEventCreation}
                            roleVariant={BUTTON_VARIANTS.CTA}
                            sizeVariant={BUTTON_SIZE.LG}
                        >
                            <span>{strings.createOrEditEventScreen.createEvent}</span>
                        </Button>
                    </div>
                </div>
                <CreateOrEditEvent
                    showCreateEvent={showCreateEvent}
                    onClose={onCancelEventCreation}
                />
            </div>
        </>
    )
}

export default EventDisplayList;