const landingStrings = {
    landingScreen: {
        title: 'LampPost',
        byline: 'Events that light up your life',
        download: 'Download the app!',
        ios: 'iOS',
        android: 'Android',
        login: 'Login',
        dashboard: 'Go to Dashboard'
    }
}

export default landingStrings;