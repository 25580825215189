const eventStrings = {
    eventDetailsScreen: {
        eventList: 'Event List',
        upcomingEvents: 'Upcoming Events',
        pastEvents: 'Past Events',
        backtoEvent: 'Back to Event',
        backtoEvents: 'Back to Events List',
        distanceAway: (distance: string) => `(${distance} away)`,
        additionalAttendees: (count: number) => `+${count} Others`,
        attendees: 'Attendees',
        cost: (amt: string) => `Cost: ${parseInt(amt) ? `$${amt}` : amt }`,
        free: 'Free',
        emptyList: 'Looks like nothing’s planned yet.',
        emptyListCta: 'Why not start something?',
        emptyListRemoveFilters: 'Or try removing filters to discover more!',
        join: 'Join Event',
        leave: 'Leave Event',
        share: 'Share Event',
        delete: 'Delete',
        edit: 'Edit',
        shareLabel: 'Invitation Emails (separate emails with a comma)',
        emptyEvent: {
            description1: "The spotlight’s on you!",
            description2: 'Choose an Event'
        }
    }
}

export default eventStrings;