const strings = {
    filters: {
        filters: 'Filters',
        types: {
            eventTypes: 'Event Categories',
            host: 'Event Host',
            name: 'Search',
            location: 'Location',
            distance: 'Distance',
            groups: 'Groups',
            dateTime: 'Date / Time',
            cost: 'Cost',
            tags: 'Tags',
            ages: 'Audience Age'
        },
        cost: {
            any : 'Any cost',
            free: 'Free',
            ten: '$10 and under',
            twenty: '$20 and under',
            fifty: '$50 and under',
            hundred: '$100 and under',
            // twoHundred: '$200 and under',
            // fiveHundred: '$500 and under',
            // thousand : '$1000 and under',
        },
        distance: {
            any: 'Any distance',
            oneMile: 'Within 1 mile',
            fiveMiles: 'Within 5 miles',
            tenMiles: 'Within 10 miles',
            twentyFiveMiles: 'Within 25 miles',
            fiftyMiles: 'Within 50 miles',
            hundredMiles: 'Within 100 miles',
        },
        tags: {
            artsAndCulture: 'Arts & Culture',
            charityAndFundraisers: 'Charity & Fundraisers',
            comedyAndEntertainment: 'Comedy & Entertainment',
            communityAndLifestyle: 'Community & Lifestyle',
            conferencesAndWorkshops: 'Conferences & Workshops',
            musicAndConcerts: 'Concerts & Music Festivals',
            educationalAndAcademic: 'Educational & Academic',
            exposAndTradeShows: 'Expos & Trade Shows',
            familyAndKids: 'Family & Kids',
            fashionAndBeauty: 'Fashion & Beauty',
            filmAndCinema: 'Film & Cinema',
            foodAndDrink: 'Food & Drink',
            healthAndWellness: 'Health & Wellness',
            holidayAndSeasonal: 'Holiday & Seasonal',
            outdoorAndNature: 'Outdoor & Nature',
            socialAndNetworking: 'Social & Networking',
            eventsAndCelebrations: 'Special Events & Celebrations',
            sportsAndFitness: 'Sports & Fitness',
            technologyAndInnovation: 'Technology & Innovation',
            travelAndAdventure: 'Travel & Adventure',
        },
        ages: {
            infant: 'Infant (0 - 2 years)',
            toddler: 'Toggler (3 - 5 years)',
            kid: 'Kid (6 - 8 years)',
            preTeen: 'Pre Teen (9 - 12 years)',
            youngTeen: 'Young Teen (13 - 15 years)',
            oldTeens: 'Old Teen (16 - 18 years)',
            youngAdult: 'Young Adult (19 - 24 years)',
            earlyCareer: 'Early Career (25 - 34 years)',
            midCareer: 'Mid Career (35 - 44 years)',
            midlife: 'Midlife (45 - 54 years)',
            preRetired: 'Pre Retired (55 - 64 years)',
            retired: 'Retired (65+ years)',
        },
        eventTypes: {
            allEvents: 'All Events',
            hosting: 'My Hosted Events',
            created: 'My Created Events',
            attending: 'My Upcoming Events',
            previous: 'My Previous Events'
        }
    }
}

export default strings;
