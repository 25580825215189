import strings from "../i18n/i18nStrings";

export const getEventDates = (startDateTime: number, endDateTime: number) => {
    const daysOfWeek = Object.values(strings.common.daysOfWeek);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const startDateTimeData = new Date(startDateTime);
    const startMonth = (startDateTimeData.getMonth() + 1).toString();
    const startDay = startDateTimeData.getDate().toString();
    const startTime = startDateTimeData.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: timezone
    });
    const startDayAbr = daysOfWeek[startDateTimeData.getDay()];

    console.log("startTime: ", startTime);
    const utcTime = startDateTimeData.toISOString();
    console.log(`UTC Time: ${utcTime}`);  // This shows the time in UTC

    const endDateTimeData = new Date(endDateTime);
    const endMonth = (endDateTimeData.getMonth() + 1).toString();
    const endDay = endDateTimeData.getDate().toString();
    const endTime = endDateTimeData.toLocaleTimeString('en-US', { 
        hour: 'numeric',
        minute: '2-digit',
        timeZone: timezone
    });
    const endDayAbr = daysOfWeek[endDateTimeData.getDay()];

    if (startMonth === endMonth && startDay === endDay) {
        return strings.common.sameDate(startDayAbr, startDay, startMonth, startTime, endTime);
    }

    return strings.common.differentDate(startDayAbr, startDay, startMonth, startTime, endDayAbr, endDay, endMonth, endTime);
}