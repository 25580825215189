import { useEffect, useRef } from "react";
import styles from "./NavMenu.module.scss";
import { useNavContext } from "../../contexts/NavContext";
import NavList from "../navList/NavList";
import { LAMPPOST_MOBILE_BREAKPOINTS } from "../../ui/constants";

const NavMenu = () => {
    const { isNavMenuOpen, setIsNavMenuOpen } = useNavContext();

    const menuRef = useRef<any>(null);
    const buttonRef = useRef<any>(null);

    useEffect(() => {
        const handleOutsideClick = (event: { target: any; }) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target) &&
                window.innerWidth >= LAMPPOST_MOBILE_BREAKPOINTS.desktop
            ) {
                setIsNavMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleOpenMenu = () => {
        setIsNavMenuOpen(prevState => !prevState);
    }

    return (
        <div className={styles.menuContainer}>
            <div
                className={styles.menuButton}
                onClick={toggleOpenMenu}
                ref={buttonRef}
            >
                <span />
                <span />
                <span />
            </div>
            {
                isNavMenuOpen && (
                    <div
                        className={styles.menuList}
                        ref={menuRef}
                    >
                        <NavList />
                    </div>
                )
            }
        </div>
    )
}

export default NavMenu;