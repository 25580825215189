import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import TagManager from "react-gtm-module";
import { lampPostFetch } from '../utils/fetchHelpers';
import {
    DELETE_USER,
    GET_SELF,
    LOGIN_USER,
    UPDATE_USER
} from '../components/common/constants/endpoints';
import { GET_SERVER } from '../components/common/constants/servers';
import { useClerk, useUser } from '@clerk/clerk-react';
import { UserInfoType } from '../types/UserType';

const tagManagerArgs = {
    gtmId: "GTM-WMVPC8MQ",
};

type AppContextValues = {
    userInfo: UserInfoType;
    userLocation: GeolocationCoordinates;
    isSignedIn: boolean;
    updateUserInfo: (changedUserInfo: any, callback?: () => void) => void;
    getUpdatedUser: () => void;
    deleteUser: () => void;
    server: string;
    showCompleteProfileModule: boolean;
    closeCompleteProfileModule: () => void;
    completeProfileDemographics: (changedUserInfo: any) => void;
}

export const AppContext = createContext({} as AppContextValues);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: { children: ReactElement }) => {
    const { isSignedIn, user } = useUser();
    const { signOut } = useClerk();

    const [userInfo, setUserInfo] = useState<UserInfoType>(null);
    const [userLocation, setUserLocation] = useState(null);
    const [showCompleteProfileModule, setShowCompleteProfileModule] = useState(() => {
        const showAsk = sessionStorage.getItem("showCompleteProfileModule");
        return showAsk === null ? null : JSON.parse(showAsk);
    });

    useEffect(() => {
        if (isSignedIn && user && !userInfo) {
            // Check if user exists, if not then create one
            // Set this user to be userInfo

            lampPostFetch(
                LOGIN_USER(user.id, user?.emailAddresses[0]?.emailAddress),
                { method: "GET" },
                (result) => {
                    setUserInfo({ ...result });

                    // Only show the Personalize modal when they first log in
                    if (showCompleteProfileModule === null && result?.completedAccount) {
                        sessionStorage.setItem("showCompleteProfileModule", `${!result.completedAccount}`);
                        setShowCompleteProfileModule(!result.completedAccount);
                    }
                }
            );
        } else {
            setUserInfo(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, user]);

    const completeProfileDemographics = (changedUserInfo: any) => {
        updateUserInfo(changedUserInfo, () => {
            setShowCompleteProfileModule(false);
            sessionStorage.setItem("showCompleteProfileModule", 'false');
        })
    }

    const getUpdatedUser = () => {
        lampPostFetch(
            GET_SELF(userInfo?._id),
            { method: "GET" },
            (result) => {
                if (result) {
                    setUserInfo({ ...result });
                }
            }
        );
    }

    const deleteUser = () => {
        lampPostFetch(
            DELETE_USER(userInfo?._id),
            { method: "DELETE" },
            () => {
                signOut();
            }
        );
    }

    const updateUserInfo = (changedUserInfo: any, callback?: () => void) => {
        lampPostFetch(
            UPDATE_USER(userInfo?.userId),
            {
                method: "PATCH",
                body: JSON.stringify({
                    ...userInfo,
                    ...changedUserInfo,
                })
            },
            (result: any) => {
                if (result) {
                    setUserInfo({ ...result });

                    if (callback) {
                        callback();
                    }
                }
            }
        );
    }

    const closeCompleteProfileModule = () => {
        sessionStorage.setItem("showCompleteProfileModule", "false");
        setShowCompleteProfileModule(false);
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.log(error.message);
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser.');
        }
    }, []);

    return (
        <AppContext.Provider
            value={{
                userInfo,
                userLocation,
                isSignedIn,
                updateUserInfo,
                getUpdatedUser,
                deleteUser,
                showCompleteProfileModule,
                closeCompleteProfileModule,
                completeProfileDemographics,
                server: GET_SERVER()
            }}
        >
            {children}
        </AppContext.Provider>
    )
}